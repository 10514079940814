import React, {
   ChangeEvent,
   HTMLAttributes,
   createContext,
   useContext,
} from "react";
import { Input } from "@/components/ui/input";
import { cn } from "@/lib/utils";

/*
 ██████  ██████  ███    ██ ████████ ███████ ██   ██ ████████
██      ██    ██ ████   ██    ██    ██       ██ ██     ██
██      ██    ██ ██ ██  ██    ██    █████     ███      ██
██      ██    ██ ██  ██ ██    ██    ██       ██ ██     ██
 ██████  ██████  ██   ████    ██    ███████ ██   ██    ██
*/

// Ajouter des états et de fonctions de changement d'état
type TContext = {
   selected?: string;
   type?: string;
   is_error?: boolean;
   read_only?: boolean;
   value?: string | number;
   onChange?: (val: string | number) => void;
   // onBlur?: () => void;
};

const theContext = createContext<TContext | null>(null);

/*
███████ ██ ███████ ██      ██████
██      ██ ██      ██      ██   ██
█████   ██ █████   ██      ██   ██
██      ██ ██      ██      ██   ██
██      ██ ███████ ███████ ██████
*/

export interface PapooInputFieldProps extends HTMLAttributes<HTMLInputElement> {
   type?: string;
   placeholder?: string;
}

const PapooInputField = React.forwardRef<
   HTMLInputElement,
   PapooInputFieldProps
>(({ id, className, ...props }: PapooInputFieldProps, ref) => {
   const context = useContext(theContext);

   const onChange = (evt: ChangeEvent<HTMLInputElement>) => {
      if (context?.onChange) {
         const value = (evt.target as HTMLInputElement).value;

         if ((evt.target as HTMLInputElement).type === "number") {
            context.onChange(parseInt(value));
         } else {
            context.onChange(value);
         }
      }
   };

   // En cas de flag clearable on imbrique l'<input> dans un <div>. Je fais un cas différent sinon, afin de pouvoir affecter le `className` à l'<input> et non pas au <div>

   return (
      <>
         <Input
            id={id}
            type={context?.type}
            readOnly={context?.read_only}
            className={cn(
               "w-full",
               context?.is_error ? "bg-jack-red-100" : "",
               className,
            )}
            value={context?.value}
            ref={ref}
            onChange={onChange}
            // onBlur={context?.onBlur}
            {...props}
         />
      </>
   );
});

/*
██████   ██████   ██████  ████████
██   ██ ██    ██ ██    ██    ██
██████  ██    ██ ██    ██    ██
██   ██ ██    ██ ██    ██    ██
██   ██  ██████   ██████     ██
*/

// export interface PapooInputProps extends ComponentProps<"div"> {
export interface PapooInputProps extends HTMLAttributes<HTMLDivElement> {
   type?: string;
   is_error?: boolean;
   read_only?: boolean;
   value?: string | number;
   onValueChange?: (value: string | number) => void;
   // onBlur?: () => void;
}

const PapooInput = ({
   className,
   id,
   type,
   is_error,
   read_only,
   children,
   value,
   onValueChange,
   // onBlur,
}: PapooInputProps) => {
   return (
      <theContext.Provider
         value={{
            onChange: onValueChange,
            // onBlur,
            value,
            is_error,
            read_only,
            type: type ?? "string",
         }}
      >
         <div
            id={id}
            className={cn("flex w-full items-center gap-2", className)}
         >
            {children}
         </div>
      </theContext.Provider>
   );
};

PapooInput.displayName = "PapooInput";

export { PapooInput, PapooInputField };
