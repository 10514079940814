import {
   Fragment,
   KeyboardEvent,
   useCallback,
   useEffect,
   useRef,
   useState,
} from "react";
// import img_login from "../assets/png/login.webp";
import { Img } from "react-image";
import logo from "../assets/png/logo.png";
import { PapooButton } from "@/components/papoo/PapooButton";
import { useNavigate } from "react-router-dom";
import { registerSW } from "virtual:pwa-register";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faPlus } from "@fortawesome/pro-solid-svg-icons";
import { db as dexie_db } from "@/stores/db";
import useHelpers from "@/Hooks/useHelpers";
import { PapooInput, PapooInputField } from "@/components/papoo/PapooInput";
import { TArticle, TInventaires } from "@/stores/types";
import moment from "moment";

function Main() {
   const navigate = useNavigate();
   // const { apiLogout } = useApi();
   // const { is_logged, is_connected, is_fetching, doLogout } = useContext(
   //    LiveUpdateContext,
   // ) as LiveUpdateContextType;
   const { getVersion, getVersionDate, getCopyright, getDevRefShort } =
      useHelpers();
   const [list_inventaires, setListInventaires] = useState<TInventaires[]>();
   const [selected_inventaire, setSelectedInventaire] =
      useState<TInventaires>();
   const [client_name, setClientName] = useState<string>();
   const [name_is_valid, setNameIsValid] = useState<boolean>();
   const [show_confirm_delete, setShowConfirmDelete] = useState<boolean>();
   const name_field = useRef<HTMLInputElement>(null);

   useEffect(() => {
      const updateSW = registerSW({
         onRegistered(r) {
            console.log("**SW++ 2 onRegistered", r);
            if (r) {
               r.update(); // On check immédiatement à l'affichage du login
            }
         },
         onNeedRefresh() {
            updateSW();
         },
      });
   }, []);

   // useEffect(() => {
   //    (async () => {
   //       setHasScans((await dexie_db.scans.toArray()).length > 0);
   //    })();
   // }, []);

   const loadDB = useCallback(async () => {
      const items = (await dexie_db.scans.toArray()).sort((inv_a, inv_b) =>
         moment(inv_a.date).isAfter(inv_b.date) ? -1 : 1,
      );
      console.log(items);

      setListInventaires(items);
   }, []);

   useEffect(() => {
      loadDB();
   }, [loadDB]);

   console.log(list_inventaires);

   useEffect(() => {
      if (list_inventaires && client_name && client_name.length > 0) {
         setNameIsValid(
            list_inventaires.find(
               (an_inventaire) => an_inventaire.name === client_name,
            ) === undefined,
         );
      } else {
         setNameIsValid(false);
      }
   }, [client_name, list_inventaires]);

   const addClient = useCallback(async () => {
      if (name_is_valid && client_name) {
         setClientName(undefined);
         await dexie_db.scans.put({
            name: client_name,
            date: new Date(),
            scans: JSON.stringify([]),
         });
         await loadDB();
      }
   }, [client_name, loadDB, name_is_valid]);

   const deleteInventaire = useCallback(async () => {
      if (selected_inventaire) {
         await dexie_db.scans.delete(selected_inventaire.name);
      }
      await loadDB();
   }, [loadDB, selected_inventaire]);

   function keyDown(evt: KeyboardEvent<HTMLInputElement>): void {
      // console.log(evt);
      if (evt.key === "Enter") {
         if (evt.target === name_field.current && name_is_valid) {
            name_field.current.blur();
            addClient();
         } else {
            evt.preventDefault();
            evt.stopPropagation();
         }
      }
   }

   return (
      <Fragment>
         <div className="relative flex h-full w-full flex-col items-center justify-center gap-10 bg-jack-tan-100">
            {show_confirm_delete && selected_inventaire && (
               <div className="absolute left-0 right-0 z-20 flex h-full w-full flex-col items-center justify-center gap-[50px] bg-black/75 text-[220%]">
                  <div className="flex flex-col items-center gap-2 text-white">
                     <span>Supprimer</span>
                     <span>{selected_inventaire.name} ?</span>
                  </div>
                  <div className="flex w-full justify-center gap-5">
                     <PapooButton
                        variant="error"
                        onClick={() => {
                           deleteInventaire();
                           setShowConfirmDelete((cur) => !cur);
                        }}
                        className="cursor-pointer px-10 py-3"
                     >
                        Oui
                     </PapooButton>
                     <PapooButton
                        variant="secondary"
                        onClick={() => setShowConfirmDelete((cur) => !cur)}
                        className="cursor-pointer px-10 py-3"
                     >
                        Non
                     </PapooButton>
                  </div>
               </div>
            )}
            {/* <Img
               id="photo-login"
               src={img_login}
               alt="Image login"
               className="absolute h-full w-full rounded-l-lg object-cover opacity-15 saturate-0"
            /> */}
            <div className="z-10 flex h-full w-full flex-col gap-5 px-[35px] pb-[35px]">
               <Img
                  src={logo}
                  alt="Logo"
                  className="mx-0 w-1/2 self-center pb-3 pt-5"
               />
               <div className="flex w-full flex-col text-center text-[18px] text-jack-tan-500">
                  <span>
                     {getVersion()} - {getCopyright()} - {getVersionDate()}
                  </span>
                  <span className="flex items-center gap-2">
                     <img
                        src="/png/bigpapoo24.png"
                        alt="Logo"
                        height={24}
                        width={24}
                     />
                     {getDevRefShort()}
                  </span>
               </div>
               <div className="flex h-full w-full flex-col overflow-auto bg-white px-4">
                  {list_inventaires?.map((an_inventaire, index) => {
                     let scans: TArticle[];

                     try {
                        scans = JSON.parse(an_inventaire.scans);
                     } catch (err) {
                        console.error(`Pb JSON "${an_inventaire.scans}"`);
                        scans = [];
                     }
                     return (
                        <div
                           className="bg-red flex w-full items-center border-b-[1px] border-gray-300 py-1"
                           key={`item_${index}`}
                           onClick={() => {
                              navigate(
                                 `/scanner?name=${encodeURIComponent(an_inventaire.name)}`,
                              );
                           }}
                        >
                           <div className="flex flex-col gap-1">
                              <span className="text-[22px]">
                                 {an_inventaire.name}
                              </span>
                              <span>
                                 {moment(an_inventaire.date).format(
                                    "DD-MM-YYYY à HH:mm",
                                 )}{" "}
                                 (
                                 {scans.length > 0
                                    ? `${scans.length} scan${scans.length > 1 ? "s" : ""}`
                                    : "Vide"}
                                 )
                              </span>
                           </div>
                           <div className="ml-auto flex items-center text-[28px]">
                              {/* <FontAwesomeIcon
                                 icon={faBarcodeRead}
                                 className="p-5 text-jack-green-500"
                                 onClick={() => {
                                    navigate(
                                       `/scanner?name=${encodeURIComponent(an_inventaire.name)}`,
                                    );
                                 }}
                              /> */}
                              <FontAwesomeIcon
                                 icon={faTrash}
                                 className="p-5 text-jack-red-500"
                                 onClick={(evt) => {
                                    setSelectedInventaire(an_inventaire);
                                    evt.stopPropagation();
                                    setShowConfirmDelete(true);
                                 }}
                              />
                           </div>
                        </div>
                     );
                  })}
               </div>

               <div className="flex w-full gap-2">
                  <PapooInput
                     value={client_name ?? ""}
                     onValueChange={(val) => setClientName(val as string)}
                     className="h-full w-full"
                  >
                     <PapooInputField
                        ref={name_field}
                        // autoFocus
                        className="ah-full w-full text-[24px] font-bold"
                        onKeyDown={keyDown}
                     />
                  </PapooInput>
                  <PapooButton
                     variant="error"
                     disabled={!name_is_valid}
                     onClick={addClient}
                     className="h-full cursor-pointer text-[120%]"
                  >
                     <FontAwesomeIcon icon={faPlus} />
                  </PapooButton>
               </div>
            </div>
         </div>
      </Fragment>
   );
}

export default Main;
