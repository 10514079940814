import { PapooButton } from "@/components/papoo/PapooButton";
// import { Html5Qrcode, CameraDevice } from "html5-qrcode";
import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./Scanner.module.scss";
import useHelpers from "@/Hooks/useHelpers";
import useSound from "use-sound"; // Si pb pour trouver TS types, voir l'astuce dans dossier `generated-types` de ce projet
import blip_snd from "@/assets/sounds/blip.mp3";
import error_snd from "@/assets/sounds/error.mp3";
import key_snd from "@/assets/sounds/key.mp3";
import {
   faDeleteLeft,
   faCheck,
   faBarcodeRead,
   faPen,
   faTrash,
} from "@fortawesome/pro-solid-svg-icons";

// import filesaver from "file-saver";
import moment from "moment";
import fileDownload from "js-file-download";
import { cn } from "@/lib/utils";
import { db as dexie_db } from "@/stores/db";
import { TArticle, TInventaires } from "@/stores/types";
import { PapooInput, PapooInputField } from "@/components/papoo/PapooInput";

const normalize_len = 5;

function Scanner() {
   const [searchParams] = useSearchParams();
   const { formatNumber, normalizeCode } = useHelpers();
   const navigate = useNavigate();
   const [prev_qty, setPrevQty] = useState<number>(0); // Si on a déjà scanné le code
   const [scanned_articles, setScannedArticles] = useState<TArticle[]>([]);
   const [is_scanner_visible, setIsScannerVisible] = useState<boolean>(true);
   const [is_keypad_visible, setIsKeypadVisible] = useState<boolean>(false);
   const [inventaire, setInventaire] = useState<TInventaires>();
   const [tmp_scan_val, setTmpScanVal] = useState<string>(""); // Le scan en cours de saisie (se termine quand saisie \n)
   const [qty, setQty] = useState<number>(0);
   const [, setUsedPreValue] = useState<boolean>(false); // Si on a utilisé un bouton "pré-rempli"
   const [is_replace_mode, setIsReplaceMode] = useState<boolean>(false); // Si true, on est en édition d'une quantité (clic sur le crayon), on n'affiche donc pas l'ancienne valeur et on ne fait pas la somme, on remplace
   const [is_real_input_field_visible, setIsRealInputFieldVisible] =
      useState<boolean>(false); // Affichage d'un champ de saisie au clavier ?
   const [play_blip] = useSound(blip_snd);
   const [play_error] = useSound(error_snd);
   const [play_key] = useSound(key_snd);
   const ref_scan_input = useRef<HTMLInputElement>(null);
   const [buffer_clavier, setBufferClavier] = useState<string>(""); // Bufferisation des saisies clavier)
   const [cur_code_scanned, setCurCodeScanned] = useState<string>(""); // Bufferisation des saisies clavier)
   const [edit_mode, setEditMode] = useState<boolean>(false); // true = on a appuyé sur le crayon

   const loadInventaire = useCallback(async () => {
      const name = searchParams.get("name");
      if (name) {
         const a_record = await dexie_db.scans.get(name);
         if (a_record) {
            try {
               setScannedArticles(
                  (JSON.parse(a_record.scans) as TArticle[]) ?? [],
               );
            } catch (err) {
               setScannedArticles([]);
            }
         } else {
            setScannedArticles([]);
         }
         setInventaire(a_record);
      } else {
         setInventaire(undefined);
      }
   }, [searchParams]);

   useEffect(() => {
      loadInventaire();
   }, [loadInventaire]);

   const showScanner = useCallback((show: boolean) => {
      setIsKeypadVisible(!show); // Montrer ou masquer le scanner ne provoque pas d'aff du keypad. Utiliser showKeypad() à la place
      setIsScannerVisible(show);
   }, []);

   const showKeypad = useCallback((show: boolean) => {
      setIsKeypadVisible(show);
      setIsScannerVisible(!show);
      setQty(0);
   }, []);

   useEffect(() => {
      console.log(cur_code_scanned);
      if (cur_code_scanned.length) {
         const found = scanned_articles.find(
            (a_scan) => a_scan.code === cur_code_scanned,
         );

         if (!found) {
            play_blip();
            setPrevQty(0);
            setIsReplaceMode(true);
            setQty(0);
         } else {
            setPrevQty(found.qty);
            setIsReplaceMode(edit_mode);
         }
      }
   }, [
      normalizeCode,
      play_blip,
      play_error,
      scanned_articles,
      cur_code_scanned,
      edit_mode,
   ]);

   function download() {
      const obj = scanned_articles.reduce((prev, article) => {
         return { ...prev, [article.code]: article.qty };
      }, {});

      fileDownload(
         JSON.stringify(obj),
         `${moment().format("DD_MM_YYYY_HH_mm_ss")}.json`,
         // "application/json",
      );
   }

   const saveToDB = useCallback(
      (list: TArticle[]) => {
         if (inventaire) {
            dexie_db.scans.put({
               name: inventaire.name,
               date: new Date(),
               scans: JSON.stringify(list),
            });
         }
      },
      [inventaire],
   );

   const validateQty = useCallback(
      (switch_to_scanner: boolean = true) => {
         if (qty > 0) {
            setScannedArticles((cur) => {
               let new_list: TArticle[];

               if (prev_qty === 0) {
                  new_list = [
                     { code: cur_code_scanned, qty } as TArticle,
                     ...cur,
                  ];
               } else {
                  new_list = cur
                     .map((a_scan) => {
                        if (a_scan.code === cur_code_scanned) {
                           return {
                              code: cur_code_scanned,
                              qty: (is_replace_mode ? 0 : a_scan.qty) + qty,
                           };
                        } else {
                           return a_scan;
                        }
                     })
                     .sort((line1, line2) => {
                        if (line1.code === cur_code_scanned) {
                           return -1;
                        } else if (line2.code === cur_code_scanned) {
                           return 1;
                        } else {
                           return 0;
                        }
                     });
               }
               saveToDB(new_list);
               return new_list;
            });
            setQty(0);
         }
         setEditMode(false);
         if (switch_to_scanner) {
            showScanner(true);
         }
      },
      [cur_code_scanned, is_replace_mode, prev_qty, qty, saveToDB, showScanner],
   );

   const addDigit = useCallback(
      (digit: number) => {
         play_key();
         setUsedPreValue((cur) => {
            if (cur) {
               // Si on a utilisé une valeur pré-remplie (un des boutons pré-remplis). On remplace par le chiffre tappé
               setQty(digit);
            } else {
               // Sinon on fonctionne comme une saisie de calculatrice
               setQty((cur) => cur * 10 + digit);
            }
            return false;
         });
      },
      [play_key],
   );

   const fillWithPreValue = useCallback(
      (new_qty: number) => {
         play_key();
         setQty(new_qty);
         setUsedPreValue(true);
      },
      [play_key],
   );

   // Convertit une lettre du clavier en le chiffre (sous forme string) qu'il y a sur cette même touche
   // Retourne la chaine initiale si pas de conversion possible
   function letterToDigit(str: string): string {
      let digit = str;
      if (str.length === 1) {
         const idx = ["À", "&", "É", '"', "'", "(", "§", "È", "!", "Ç"].indexOf(
            str,
         );
         digit = idx === -1 ? str : `${idx}`;
      }
      return digit;
   }

   const onEvt = useCallback(
      (evt: globalThis.KeyboardEvent) => {
         if (!is_real_input_field_visible) {
            console.log(evt.key);

            if (evt.key === "Enter") {
               setBufferClavier((cur) => `${cur}\n`);
            } else if (evt.key === "Backspace") {
               setBufferClavier((cur) => cur.slice(0, -1));
            } else {
               const letter = evt.key.toLocaleUpperCase();
               if (letter.length === 1) {
                  const digit = letterToDigit(letter);
                  setBufferClavier((cur) => {
                     return `${cur}${digit}`;
                  });
               }
               // }
            }
         }
      },
      [is_real_input_field_visible],
   );

   useEffect(() => {
      setBufferClavier((cur) => {
         console.log(`>>"${cur}"`);

         if (cur && cur.length > 0) {
            const found = cur.match(/(^\$?[A-Z0-9]+)\n/);

            console.log("FOUND", found);

            if (found) {
               let new_code = found[1];

               console.log("TRAITEMENT", new_code);
               if (new_code[0] === "$") {
                  validateQty(false);
                  play_key();
                  new_code = new_code.slice(1);
               }
               setCurCodeScanned(normalizeCode(new_code, normalize_len));
               // setTmpScanVal(new_code);
               showKeypad(true);
               // return found[2];
               return "";
            } else {
               console.log(`Rien trouvé dans "${cur}"`);

               return cur;
            }
         } else {
            return cur;
         }
      });
   }, [buffer_clavier, normalizeCode, play_key, showKeypad, validateQty]);

   useEffect(() => {
      document.addEventListener("keydown", onEvt, false);

      return () => {
         document.removeEventListener("keydown", onEvt, false);
      };
   }, [onEvt]);

   const showRealInputField = useCallback(
      (state: boolean) => {
         setIsRealInputFieldVisible(state);
         if (state) {
            setTmpScanVal("");
            setTimeout(() => {
               ref_scan_input.current?.focus();
            }, 2500);
         } else {
            ref_scan_input.current?.blur();
            showKeypad(true);
         }
      },
      [showKeypad],
   );

   const changeRealInputField = useCallback((val: string) => {
      setTmpScanVal(val.toUpperCase());
   }, []);

   const deleteLine = useCallback(
      (code: string) => {
         setScannedArticles((cur) => {
            const new_list = cur.filter((a_line) => a_line.code !== code);

            saveToDB(new_list);
            return new_list;
         });
      },
      [saveToDB],
   );

   console.log("is_keypad_visible", is_keypad_visible);
   console.log("is_scanner_visible", is_scanner_visible);

   return (
      <div className="relative flex h-full grow-0 flex-col items-center justify-start gap-5 bg-jack-tan-100">
         {/* _Buf={buffer_clavier}|_Scanned={cur_code_scanned}|_Qty={qty}|_Prev=
         {prev_qty} */}
         {/* {is_replace_mode ? "REP" : "NORM"}_
         {is_keypad_visible ? "KPV" : "KPI"}_{is_scanner_visible ? "SV" : "SI"}_
         {tmp_scan_val}_ */}
         <div className={cn("flex h-full w-full flex-col gap-[20px] p-[30px]")}>
            <div
               className={cn(
                  "mx-[-30px] flex h-[100px] max-h-[100px] gap-5 border-b-[1px] px-[30px] pb-[20px] drop-shadow-sm",
                  styles.keypad,
               )}
            >
               {!is_scanner_visible && (
                  <PapooButton
                     variant="success"
                     onClick={() => {
                        setCurCodeScanned("");
                        setEditMode(false);
                        showScanner(!is_scanner_visible);
                        setBufferClavier("");
                        play_key();
                     }}
                     className="h-full w-[70px] min-w-[70px]"
                     // className="bg-jack-green-500 hover:bg-jack-green-500 active:bg-jack-green-500"
                  >
                     <FontAwesomeIcon
                        icon={faBarcodeRead}
                        className="text-[150%]"
                     />
                  </PapooButton>
               )}
               {is_real_input_field_visible && (
                  <div className="flex w-full gap-2">
                     <PapooInput
                        value={tmp_scan_val}
                        onValueChange={(val) => changeRealInputField(`${val}`)}
                        className="h-full w-full"
                     >
                        <PapooInputField
                           ref={ref_scan_input}
                           autoFocus
                           className="h-full text-right text-[36px] font-bold"
                        />
                     </PapooInput>
                     <PapooButton
                        variant="success"
                        onClick={() => {
                           setTmpScanVal((cur) => {
                              if (cur.length > 0) {
                                 // setBufferClavier(() => `${tmp_scan_val}\n`);
                                 setCurCodeScanned(
                                    normalizeCode(cur, normalize_len),
                                 );
                              }
                              showRealInputField(false);
                              return "";
                           });
                        }}
                        className="cursor-pointer"
                     >
                        OK
                     </PapooButton>
                  </div>
               )}
               {!is_real_input_field_visible && (
                  <div
                     className="flex basis-full items-center justify-end gap-2 rounded-lg border-4 border-jack-gray-200 px-5 text-[30px] font-extrabold"
                     onClick={() => showRealInputField(true)}
                  >
                     {!is_scanner_visible && (
                        <span className="mr-auto pl-3 text-[18px] text-jack-gray-300">
                           {cur_code_scanned}
                        </span>
                     )}
                     <span className="text-nowrap text-jack-gray-300">
                        {!is_scanner_visible && prev_qty && !is_replace_mode
                           ? `${formatNumber(prev_qty)} + `
                           : ""}
                     </span>
                     <span className="text-nowrap">
                        {is_scanner_visible ? tmp_scan_val : formatNumber(qty)}
                     </span>
                  </div>
               )}
            </div>

            {/* <div id="reader" className="flex w-full"></div>
         {cur_code}
         {got_new_scan && (
            <div className="absolute z-10 flex h-full w-full bg-green-500/50"></div>
         )}
         {got_existing_scan && (
            <div className="absolute z-10 flex h-full w-full bg-red-500/50"></div>
         )}
         <div>
            {scanned_articles.map((a_scan) => {
               return (
                  <span id={a_scan.code}>
                     {a_scan.qty} x {a_scan.code}
                  </span>
               );
            })}
         </div> */}
            {is_scanner_visible && (
               <div className="flex h-[calc(100%-200px)] w-full flex-col gap-1">
                  {inventaire && (
                     <span className="w-full text-center text-[16px]">
                        {inventaire.name} -{" "}
                        {moment(inventaire.date).format("DD-MM-YYYY à HH:mm")}
                     </span>
                  )}
                  <div className="flex h-full flex-col overflow-auto font-mono text-[28px]">
                     {scanned_articles.map((an_article, index) => {
                        return (
                           <div className="flex items-center py-1">
                              <span key={`article_${index}`}>
                                 {an_article.code} x {an_article.qty}
                              </span>
                              <div className="agap-[30px] ml-auto flex items-center">
                                 <FontAwesomeIcon
                                    icon={faPen}
                                    className="p-5 text-jack-green-500"
                                    onClick={() => {
                                       setEditMode(true);
                                       setCurCodeScanned(an_article.code);
                                       showKeypad(true);
                                    }}
                                 />
                                 <FontAwesomeIcon
                                    icon={faTrash}
                                    className="p-5 text-jack-red-500"
                                    onClick={() => {
                                       deleteLine(an_article.code);
                                    }}
                                 />
                              </div>
                           </div>
                        );
                     })}
                  </div>
               </div>
            )}
            {is_keypad_visible && (
               <>
                  <div
                     className={cn(
                        "grid h-[calc(50%-100px)] w-full grid-flow-row grid-cols-3 grid-rows-3 gap-[15px]",
                        styles.keypad,
                     )}
                  >
                     <PapooButton onClick={() => addDigit(1)}>1</PapooButton>
                     <PapooButton onClick={() => addDigit(2)}>2</PapooButton>
                     <PapooButton onClick={() => addDigit(3)}>3</PapooButton>
                     <PapooButton onClick={() => addDigit(4)}>4</PapooButton>
                     <PapooButton onClick={() => addDigit(5)}>5</PapooButton>
                     <PapooButton onClick={() => addDigit(6)}>6</PapooButton>
                     <PapooButton onClick={() => addDigit(7)}>7</PapooButton>
                     <PapooButton onClick={() => addDigit(8)}>8</PapooButton>
                     <PapooButton onClick={() => addDigit(9)}>9</PapooButton>
                     <PapooButton
                        onClick={() => {
                           setUsedPreValue((used) => {
                              if (used) {
                                 setQty(0);
                              } else {
                                 setQty((cur) => Math.floor(cur / 10));
                              }
                              return false;
                           });
                           play_key();
                        }}
                        className="bg-red-500 text-white hover:bg-jack-red-700"
                     >
                        <FontAwesomeIcon icon={faDeleteLeft} />
                     </PapooButton>
                     <PapooButton onClick={() => addDigit(0)}>0</PapooButton>
                     <PapooButton
                        onClick={() => {
                           validateQty();
                           play_key();

                           // setCurCodeScanned("");
                        }}
                        className="bg-jack-green-500 text-white hover:bg-jack-green-700"
                     >
                        <FontAwesomeIcon icon={faCheck} />
                     </PapooButton>
                  </div>
                  <div
                     className={cn(
                        "grid h-[calc(50%-100px)] w-full grid-flow-row grid-cols-3 grid-rows-3 gap-[15px] border-t-4 border-jack-gray-400 pt-[25px]",
                        styles.keypad,
                     )}
                  >
                     <PapooButton onClick={() => fillWithPreValue(10)}>
                        10
                     </PapooButton>
                     <PapooButton onClick={() => fillWithPreValue(15)}>
                        15
                     </PapooButton>
                     <PapooButton onClick={() => fillWithPreValue(20)}>
                        20
                     </PapooButton>
                     <PapooButton onClick={() => fillWithPreValue(25)}>
                        25
                     </PapooButton>
                     <PapooButton onClick={() => fillWithPreValue(30)}>
                        30
                     </PapooButton>
                     <PapooButton onClick={() => fillWithPreValue(40)}>
                        40
                     </PapooButton>
                     <PapooButton onClick={() => fillWithPreValue(50)}>
                        50
                     </PapooButton>
                     <PapooButton onClick={() => fillWithPreValue(75)}>
                        75
                     </PapooButton>
                     <PapooButton onClick={() => fillWithPreValue(100)}>
                        100
                     </PapooButton>
                  </div>
               </>
            )}
            {/* <div className="flex gap-5">
            {is_scanner_visible && (
               <PapooButton onClick={() => showScanner(false)}>
                  Arrêter
               </PapooButton>
            )}
            {!is_scanner_visible && (
               <PapooButton
                  onClick={() => {
                     showScanner(true);
                  }}
               >
                  Scanner
               </PapooButton>
            )}
            {!is_scanner_visible &&
               scanned_articles.length > 0 &&
               !is_keypad_visible && (
                  <PapooButton onClick={() => download()}>
                     Télécharger
                  </PapooButton>
               )}
            {!is_scanner_visible && (
               <PapooButton variant="error" onClick={() => navigate("/")}>
                  Quitter
               </PapooButton>
               )}
               </div> */}
            <div className="mx-[-30px] flex h-[60px] max-h-[60px] justify-evenly gap-5 border-t-[1px] px-[30px] pt-[20px] drop-shadow-top-sm">
               {/* <div className="apb-[30px] mt-auto flex justify-evenly"> */}
               <PapooButton variant="success" onClick={() => download()}>
                  Télécharger
               </PapooButton>
               <PapooButton variant="error" onClick={() => navigate("/")}>
                  Liste inventaires
               </PapooButton>
            </div>
         </div>
      </div>
   );
}

export default Scanner;
