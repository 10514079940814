import { Routes, Route } from "react-router-dom";
import Main from "@/pages/Main";
import Scanner from "@/pages/Scanner";
import "./App.css";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faSpinnerScale } from "@fortawesome/pro-solid-svg-icons";
// import "moment/dist/locale/fr";
import { GlobalContextProvider } from "@/Contexts/GlobalContext.js";

function App() {
   return (
      <GlobalContextProvider>
         <div className="flex-grow">
            {/* <div className="absolute">_Logged? {isLogged ? "Y" : "N"}_</div> */}
            <Routes>
               <Route path="/" element={<Main />} />
               <Route path="/scanner" element={<Scanner />} />
            </Routes>
         </div>
      </GlobalContextProvider>
   );
}

export default App;
