import { useCallback } from "react";
import moment from "moment";

// Aussi dans package.json
const version = "0.9.1";

const versionDate = "2024-11-13";
const copyright = "© Éditions Jack";
const devRef = "Conception et développement : Gildas QUINIOU (SAS Big Papoo)";
const devRefShort = "Concept. & dével. : Gildas QUINIOU";

const useHelpers = () => {
   const getVersion = useCallback(() => {
      return version;
   }, []);

   // const getSWVersion = useCallback(() => {
   //    return localStorage.getItem("sw_version");
   // }, [])

   const getVersionDate = useCallback(() => {
      return versionDate;
   }, []);

   const getCopyright = useCallback(() => {
      return copyright;
   }, []);

   const getDevRef = useCallback(() => {
      return devRef;
   }, []);

   const getDevRefShort = useCallback(() => {
      return devRefShort;
   }, []);

   // val peut être String, Number (int ou float)
   // prec est la précision maxi après la virgule
   // si fill vaut true, des zéros sont ajoutés après les chiffres après la virgule si nécessaire (prec doit être > 0)
   const formatNumber = useCallback(
      (val: number | string, prec = 0, fill = false) => {
         let tmp = val;
         const minimumFractionDigits = fill ? prec : 0;

         // log(val, tmp);

         if (typeof val === "string") {
            tmp = parseFloat(val);
         }
         if (typeof tmp === "number") {
            // log("ICI...", lang, val)
            // log(tmp.toLocaleString(lang, { minimumFractionDigits, maximumFractionDigits: prec }))
            return tmp
               .toLocaleString("fr-FR", {
                  minimumFractionDigits,
                  maximumFractionDigits: prec,
               })
               .replace(/[\u202f]/g, " "); // On remplace les espaces insécables par de vrais espaces.
         } else {
            return "?" + tmp;
         }
      },
      [],
   );

   /**
    * Formate une date Date en AAAA-MM-JJ
    * "DMY" = "17-07-2024", "YMD" = "2024-07-17", "DMy" = "17-07-24", "yMD" = "24-07-17", "DmY" = "17 juil. 2024", "YmD" = "2024 juil. 17", "DMy" = "17 juil. 24", mais "yMD" = "24 juil. 17" n'est pas compréhensible, donc pas pris en compte
    */
   const formatDate = useCallback(
      (
         date: Date | undefined,
         format:
            | "DMY"
            | "YMD"
            | "DMy"
            | "yMD"
            | "DmY"
            | "YmD"
            | "Dmy"
            | "dmy"
            | "dmY",
      ): string => {
         let ret = "";
         const day = moment(date).date();

         // console.log("___", date, day, format);

         if (date) {
            if (format === "DMY") {
               ret = moment(date).format("DD-MM-YYYY");
            } else if (format === "YMD") {
               ret = moment(date).format("YYYY-MM-DD");
            } else if (format === "DMy") {
               ret = moment(date).format("DD-MM-YY");
            } else if (format === "yMD") {
               ret = moment(date).format("YY-MM-DD");
            } else if (format === "DmY") {
               ret = moment(date).format("DD MMM YYYY");
            } else if (format === "YmD") {
               ret = moment(date).format("YYYY MMM DD");
            } else if (format === "Dmy") {
               ret = moment(date).format("DD MMM YY");
            } else if (format === "dmy") {
               if (day === 1) {
                  ret =
                     moment.localeData().ordinal(1) +
                     moment(date).format(" MMM YY");
               } else {
                  ret = moment(date).format("D MMM YY");
               }
            } else if (format === "dmY") {
               if (day === 1) {
                  ret =
                     moment.localeData().ordinal(1) +
                     moment(date).format(" MMM YYYY");
               } else {
                  ret = moment(date).format("D MMM YYYY");
               }
            } else {
               ret = "???";
            }
         }

         return ret;
      },
      [],
   );

   /**
    * Complète par des zéros ou autre symbole fourni dans `padcar` (sur une longueur définie par `length`) un code avec ou sans lettre(s) en tête et renvoie en MAJ par défaut
    */
   const normalizeCode = useCallback(
      (
         code: string,
         length: number,
         padcar: string = "0",
         uppercase: boolean = true,
      ): string => {
         let parts: RegExpMatchArray | null;
         let res: string;

         if (
            // code.length <= length &&
            (parts = code.match(/^([^0-9]*)0*([0-9]+.*)$/))
         ) {
            // On ajoute les 0 nécessaires pour faire `length` de long. Ex: 002 -> 00002, GA3 -> GA003, Z05 -> Z0005
            res =
               parts[1] + parts[2].padStart(length - parts[1].length, padcar);
         } else {
            res = code;
         }
         return uppercase ? res.toUpperCase() : res;
      },
      [],
   );

   return {
      formatNumber,
      formatDate,
      normalizeCode,
      getVersion,
      getVersionDate,
      getCopyright,
      getDevRef,
      getDevRefShort,
   };
};

export default useHelpers;
